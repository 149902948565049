import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const query = graphql`
  query LayerTemplateQuery(
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
    $layerId: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      layer: layerById(id: $layerId) {
        ...Layer
        notes: notesList(
          filter: { genders: { contains: [ $genderId ] }},
        ) {
          ...Note
        }
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { layer } = data.results
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = `${layer.name} Notes`
  const parent = 'Pyramid'
  const title = `${page} - Fragrance ${parent}`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: parent,
      slug: links.layers,
    },
    {
      name: layer.name,
      slug: layer.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: layer.about,
    image: imageMain.src,
    keywords,
    name: title,
    thing: layer,
    slug: layer.slug,
  }

  return (
    <Layout>
      <SEO
        description={layer.about}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <Image
        alt={layer.name}
        credit={layer.image}
        fixed={imageMain}
      />

      {layer.about && (
        <Paragraph>
          {layer.about}
        </Paragraph>
      )}

      <Heading level={2}>{entity.singular} {layer.name} Notes</Heading>
      <List>
        {layer.notes.map(note => (
          <ListItem key={note.id}>
            <Link href={note.slug}>
              {note.name}
            </Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
